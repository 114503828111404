/*
 * @Descripttion: 
 * @Author: renmingming
 * @Date: 2022-08-24 17:07:10
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-15 15:19:23
 */
// [0, '其他费'],
// [1, '人工费'],
// [2, '材料费'],
// [3, '机械费'],
// [4, '设备费'],
// [5, '主材费'],
/**
 * 类别
 */
export enum KindType {
  OTHER = 0,
  R = 1, // 人工费
  C = 2, // 材料费
  J = 3, // 机械费
  S = 4, // 设备费
  Z = 5 // 主材费
}

export interface TreeType{
  levelType: number;
  levelCode: number;
  classLevel: string;
  child?: TreeType[]
}

export interface MaterialType {
  area: string,
  csMarketPrice: number,
  dePrice: number,
  sequenceNbr?: string;
  serialNumber: number;
  id?: string,
  kind: number,
  libraryCode: string,
  marketPrice: number,
  materialCategory: string,
  materialCode: string,
  materialName: string,
  priceDate: string,
  specification: string,
  taxRemoval: number,
  unit: string,
  resQty: number,
  sumNub: number,
  isError?: number,
  replacePrice?: number, // 替换需要加的值
  initMarketPrice?: number, // 每轮得初始市场价格
}