/*
 * @Descripttion: 
 * @Author: renmingming
 * @Date: 2022-09-07 10:26:15
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-07 10:27:01
 */

import { KindType } from '@/interface/materialMachineLibrary';
const kindMap = new Map([
  [KindType.OTHER, '其他费'],
  [KindType.R, '人工费'],
  [KindType.C, '材料费'],
  [KindType.J, '机械费'],
  [KindType.S, '设备费'],
  [KindType.Z, '主材费'],
]);
/**
 * 材料类型
 * @param kind 
 * @returns 
 */
export function getKindText(kind) {
  return kindMap.get(kind);
}