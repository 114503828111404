












































































import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import { getKindText as kindText } from '@/views/enterpriseLibrary';
export default Vue.extend({
  name: 'EditForm',
  props: {
    title: {
      type: String,
      default: '编辑',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      rules: {
        marketPrice: [
          { required: true, message: '请输入参考价', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    save() {
      (this.$refs.ruleForm as FormModel).validate((valid) => {
        if (valid) {
          this.$emit('save', this.formData);
        }
      });
    },
    cancel() {
      this.$emit('update:visible', false);
    },
    getKindText(kind) {
      return kindText(kind);
    },
    blurHandler() {
      this.formData.marketPrice = this.formData.marketPrice.toFixed(2);
    },
  },
});
